<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:headerTitle>
              <div class="text-center">
                  <h5 class="card-title"><i class="ri-edit-line"></i> {{ $t('externalUserIrrigation.complain_application') }}</h5>
              </div>
          </template>
          <template v-slot:body>
            <profile-status-msg :preConditionLoader="preConditionLoader" :profileStatus="profileStatus" :profile-url="'/irrigation/my-profile'" :inForm="true"/>
            <b-overlay :show="false">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(storeUpdate)" @reset.prevent="reset" >
              <div @click="displayProfileCheckAlert">
                <b-row>
                    <b-col sm="4">
                        <ValidationProvider name="Organization" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="org_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                  plain
                                  v-model="complainApplication.org_id"
                                  :options="orgList"
                                  id="org_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="email">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="email"
                            slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('externalUserIrrigation.email') }}
                              </template>
                            <b-form-input
                                plain
                                v-model="complainApplication.email"
                                id="email"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                            </b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="Division" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="far_division_id"
                              slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                </template>
                              <b-form-select
                              plain
                              v-model="complainApplication.far_division_id"
                              :options="divisionList"
                              id="far_division_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              disabled
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="District" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="far_district_id"
                              slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                </template>
                              <b-form-select
                              plain
                              v-model="complainApplication.far_district_id"
                              :options="districtList"
                              id="far_district_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              disabled
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="Upazila" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="far_upazila_id"
                            slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                              </template>
                            <b-form-select
                            plain
                            v-model="complainApplication.far_upazilla_id"
                            :options="upazilaList"
                            id="far_upazila_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            disabled
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="Union" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="far_union_id"
                              slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                </template>
                              <b-form-select
                              plain
                              v-model="complainApplication.far_union_id"
                              :options="unionList"
                              id="far_union_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              disabled
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="Subject (En)" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="subject"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('externalUserIrrigation.subject_en')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                plain
                                v-model="complainApplication.subject"
                                id="subject"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                            </b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="Subject (Bn)" vid="subject_bn">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            :label="$t('externalUserIrrigation.subject_bn')"
                            label-for="subject_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <b-form-input
                                plain
                                v-model="complainApplication.subject_bn"
                                id="subject_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                            </b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="office_id"
                            slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('warehouseFarmer.office')}} <span class="text-danger">*</span>
                              </template>
                            <b-form-select
                              plain
                              v-model="complainApplication.office_id"
                              :options="officeList"
                              id="office_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="6">
                        <ValidationProvider name="Details (En)" rules="required">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="details"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('externalUserIrrigation.details_en')}} <span class="text-danger">*</span>
                            </template>
                            <vue-editor
                              useCustomImageHandler
                              id="details"
                              v-model="complainApplication.details"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></vue-editor>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="6">
                        <ValidationProvider name="Details (Bn)" vid="details_bn">
                            <b-form-group
                            class="row"
                            label-cols-sm="12"
                            :label="$t('externalUserIrrigation.details_bn')"
                            label-for="details_bn"
                            slot-scope="{ valid, errors }"
                            >
                            <vue-editor
                              useCustomImageHandler
                              id="details_bn"
                              v-model="complainApplication.details_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></vue-editor>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6">
                      <ValidationProvider name="Attachment" vid='attachment'>
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            :label="$t('externalUserIrrigation.attachment')"
                            label-for="attachment"
                            slot-scope="{ valid, errors }"
                        >
                           <b-form-file
                            @change="onFileChange"
                            v-model="complainApplication.attachment"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                </b-row>
              </div>
              <div class="text-right mt-3">
                <button class="btn btn-primary mr-3" type="submit">{{ saveBtnName }}</button>
                <b-button variant="danger" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
              </div>
            </b-form>
            </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import ProfileStatusMsg from '@/modules/external-user-service/irrigation/components/ProfileStatusMsg'
import { farmerComplainStore, farmerInfo } from '../../api/routes'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'FormLayout',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider,
    ProfileStatusMsg,
    VueEditor
  },
  created () {
    this.checkUserProfile()
    this.checkUser()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.farmer_id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      profileStatus: 0,
      preConditionLoader: false,
      complainApplication: {
          org_id: 0,
          email: '',
          far_division_id: '0',
          far_district_id: '0',
          far_upazilla_id: '0',
          far_union_id: '0',
          office_id: '0',
          subject: '',
          subject_bn: '',
          details: '',
          details_bn: '',
          attachment: []
      },
      upload_attachment: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      officeList: []
    }
  },
  computed: {
    divisionList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.divisionList
    },
    orgList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
    }
  },
  watch: {
    'complainApplication.far_division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.districtList = this.getDistrictList(newVal)
      }
    },
    'complainApplication.far_district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
      }
    },
    'complainApplication.far_upazilla_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.unionList = this.getUnionList(newVal)
        this.officeList = this.getOfficeList(newVal)
      }
    }
  },
  methods: {
    checkUserProfile () {
        this.preConditionLoader = true
        RestApi.getData(irriSchemeServiceBaseUrl, farmerInfo + '?with_data=' + false).then(response => {
            if (response.success) {
                this.profileStatus = response.data.status === 2 ? 4 : 0
            }
            this.preConditionLoader = false
        })
    },
    onFileChange (e) {
      this.upload_attachment = e.target.files[0]
    },
    async storeUpdate () {
      if (this.profileStatus === 0) {
          this.displayProfileCheckAlert()
          return
      }
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true })
      let result = null
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData()
      Object.keys(this.complainApplication).map(key => {
        if (key === 'attachment') {
          formData.append(key, this.upload_attachment)
        } else {
          formData.append(key, this.complainApplication[key])
        }
      })
        result = await RestApi.postData(irriSchemeServiceBaseUrl, farmerComplainStore, formData, config)
        this.$emit('saved')
        this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: true })
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.farmer_id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-1')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList
      if (divisionId) {
        return districtList.filter((district) => {
            if (typeof district !== 'undefined') {
                return district.division_id === divisionId
            }
        })
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    checkUser () {
      RestApi.getData(irriSchemeServiceBaseUrl, farmerInfo + '?with_data=0').then(response => {
        if (response.success) {
          this.complainApplication = Object.assign(this.complainApplication, response.data)
        }
      })
    },
    getOfficeList (upazilaId = null) {
      const officeList = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(office => office.district_id === this.complainApplication.far_district_id && office.status === 0)
      if (upazilaId) {
        const tmpOfficeList = officeList.filter(item => item.upazilla_id === upazilaId && item.status === 0)
        if (tmpOfficeList.length > 0) {
          return tmpOfficeList
        }
      }
      return officeList
    },
    displayProfileCheckAlert () {
      if (this.profileStatus !== 0) {
          return
      }
      // const msg = this.$t('externalPanel.profile_complete_msg_part1') + '<a href="/irrigation/my-profile">links</a> ' + this.$t('externalPanel.profile_complete_msg_part2')
      this.$swal({
          title: this.$t('externalPanel.profile_complete_msg'),
          showCloseButton: true,
          confirmButtonText: 'Close',
          // showCancelButton: true,
          // html: msg
          focusConfirm: false
      })
    }
  }
}
</script>
